<template>
    <centered-layout>
        <div class="text-center">
            <img
                src="@/assets/images/global/sorryidentification.svg"
                width="200px"
                alt="Magnifying Glass with ID"
            >
        </div>
        <div class="text-center">
            <h5 class="fw-light">
                {{ $t('pages.shared.unableToVerifyIdentity.title') }}
            </h5>
        </div>
        <p class="text-muted">
            {{ $t('pages.shared.unableToVerifyIdentity.subTitle') }}
        </p>
        <div class="d-grid">
            <a
                class="btn btn-secondary mt-3"
                @click="onClick"
                @keydown.enter="onClick"
            >{{ $t('pages.shared.unableToVerifyIdentity.cta') }}</a>
        </div>
    </centered-layout>
</template>

<script>
    // TODO: explore condensing this file and Sorry.vue into one component
    import { logger } from '@/utils/logger'
    import CenteredLayout from '@/layouts/Centered'

    export default {
        components: {
            'centered-layout': CenteredLayout,
        },
        mounted: function () {
            this.$logEvent('view_identity_unable_to_verify')
        },
        data() {
            return {
                isRedirecting: false,
            }
        },
        methods: {
            onClick: async function () {
                if (this.isRedirecting) return

                this.isRedirecting = true
                logger.info('Moving backwards one page in the history...')
                await this.$router.go(-1)
            },
        },
    }
</script>
